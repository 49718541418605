var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "v-switch",
                {
                  staticClass: "mt-0",
                  attrs: {
                    dense: "",
                    "hide-details": "",
                    label: _vm.description,
                    readonly: !_setup.props.editMode,
                  },
                  model: {
                    value: _setup.selectedData.active,
                    callback: function ($$v) {
                      _vm.$set(_setup.selectedData, "active", $$v)
                    },
                    expression: "selectedData.active",
                  },
                },
                [_vm._v("actief")]
              ),
            ],
            1
          ),
          !_vm.onlyCategories
            ? _c(
                "v-col",
                [
                  _setup.selectedData.active
                    ? _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(_setup.ValidationProvider, {
                                    attrs: {
                                      name: "Grenst aan",
                                      rules: "required",
                                      vid: `grenstAan-${_vm.kindKey}`,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("v-select", {
                                                class: {
                                                  noBorders:
                                                    !_setup.props.editMode,
                                                },
                                                attrs: {
                                                  label: "Grenst aan",
                                                  "hide-details": "auto",
                                                  items: _vm.boundaryTypes,
                                                  "item-text": "description",
                                                  "item-value": "key",
                                                  "persistent-placeholder": "",
                                                  dense: "",
                                                  "error-messages": errors,
                                                  outlined:
                                                    _setup.props.editMode,
                                                  readonly:
                                                    !_setup.props.editMode,
                                                },
                                                model: {
                                                  value:
                                                    _setup.selectedData
                                                      .boundaryTypeKey,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _setup.selectedData,
                                                      "boundaryTypeKey",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectedData.boundaryTypeKey",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2577797699
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(_setup.ValidationProvider, {
                                    attrs: {
                                      name: "Oppervlakte",
                                      rules: "decimal:4|between:0,99999.9999",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("v-text-field", {
                                                ref: "surface",
                                                class: {
                                                  noBorders:
                                                    !_setup.props.editMode,
                                                },
                                                attrs: {
                                                  label: "Oppervlakte",
                                                  "hide-details": "auto",
                                                  type: "number",
                                                  step: "0.01",
                                                  "persistent-placeholder": "",
                                                  dense: "",
                                                  value:
                                                    _setup.selectedData
                                                      .surface != undefined
                                                      ? Math.abs(
                                                          _setup.selectedData
                                                            .surface
                                                        )
                                                      : undefined,
                                                  "error-messages": errors,
                                                  outlined:
                                                    _setup.props.editMode,
                                                  readonly:
                                                    !_setup.props.editMode,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    _setup.selectedData.surface =
                                                      $event !== ""
                                                        ? $event
                                                        : undefined
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3262681237
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(_setup.ValidationProvider, {
                                    attrs: {
                                      name: "Aantal",
                                      rules: "decimal:2|between:0,99999.99",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("v-text-field", {
                                                ref: "count",
                                                class: {
                                                  noBorders:
                                                    !_setup.props.editMode,
                                                },
                                                attrs: {
                                                  label: "Aantal",
                                                  "hide-details": "auto",
                                                  type: "number",
                                                  step: "1",
                                                  "persistent-placeholder": "",
                                                  dense: "",
                                                  value:
                                                    _setup.selectedData.count !=
                                                    undefined
                                                      ? Math.abs(
                                                          _setup.selectedData
                                                            .count
                                                        )
                                                      : undefined,
                                                  "error-messages": errors,
                                                  outlined:
                                                    _setup.props.editMode,
                                                  readonly:
                                                    !_setup.props.editMode,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    _setup.selectedData.count =
                                                      $event !== ""
                                                        ? $event
                                                        : undefined
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3722226453
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _setup.selectedData.active
                ? _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      !_setup.hasUvalue
                        ? _c(
                            "v-row",
                            { staticClass: "category-border" },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    class: {
                                      noBorders: !_setup.props.editMode,
                                    },
                                    attrs: {
                                      label: "Rc categorie",
                                      "hide-details": "auto",
                                      items:
                                        _setup.filterInsulationCategoriesRc,
                                      "item-text": "description",
                                      "item-value": "name",
                                      "persistent-placeholder": "",
                                      dense: "",
                                      outlined: _setup.props.editMode,
                                      readonly: !_setup.props.editMode,
                                    },
                                    model: {
                                      value: _setup.selectedData.rcCategory,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _setup.selectedData,
                                          "rcCategory",
                                          $$v
                                        )
                                      },
                                      expression: "selectedData.rcCategory",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c(_setup.ValidationProvider, {
                                    attrs: {
                                      name: "Rc-waarde",
                                      rules: "decimal:4|between:0,99999.9999",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("v-text-field", {
                                                ref: "rcValue",
                                                class: {
                                                  noBorders:
                                                    !_setup.rcValueIsEdit,
                                                },
                                                attrs: {
                                                  label: "Rc-waarde",
                                                  "hide-details": "auto",
                                                  type: "number",
                                                  step: "0.01",
                                                  "persistent-placeholder": "",
                                                  dense: "",
                                                  "error-messages": errors,
                                                  outlined:
                                                    _setup.rcValueIsEdit,
                                                  readonly:
                                                    !_setup.rcValueIsEdit,
                                                },
                                                model: {
                                                  value:
                                                    _setup.selectedData.rcValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _setup.selectedData,
                                                      "rcValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectedData.rcValue",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      173996759
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _setup.hasUvalue
                        ? _c(
                            "v-row",
                            { staticClass: "category-border" },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    class: {
                                      noBorders: !_setup.props.editMode,
                                    },
                                    attrs: {
                                      label: "U categorie",
                                      "hide-details": "auto",
                                      items: _setup.filterInsulationCategoriesU,
                                      "item-text": "description",
                                      "item-value": "name",
                                      "persistent-placeholder": "",
                                      dense: "",
                                      outlined: _setup.props.editMode,
                                      readonly: !_setup.props.editMode,
                                    },
                                    model: {
                                      value: _setup.selectedData.uCategory,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _setup.selectedData,
                                          "uCategory",
                                          $$v
                                        )
                                      },
                                      expression: "selectedData.uCategory",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c(_setup.ValidationProvider, {
                                    attrs: {
                                      name: "U-waarde",
                                      rules: "decimal:4|between:0,20.0",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("v-text-field", {
                                                ref: "uValue",
                                                class: {
                                                  noBorders:
                                                    !_setup.uValueIsEdit,
                                                },
                                                attrs: {
                                                  label: "U-waarde",
                                                  "hide-details": "auto",
                                                  type: "number",
                                                  step: "0.01",
                                                  "persistent-placeholder": "",
                                                  dense: "",
                                                  "error-messages": errors,
                                                  outlined: _setup.uValueIsEdit,
                                                  readonly:
                                                    !_setup.uValueIsEdit,
                                                },
                                                model: {
                                                  value:
                                                    _setup.selectedData.uValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _setup.selectedData,
                                                      "uValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectedData.uValue",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      901853335
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _setup.hasGvalue
                        ? _c(
                            "v-row",
                            { staticClass: "category-border" },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    class: {
                                      noBorders: !_setup.props.editMode,
                                    },
                                    attrs: {
                                      label: "G categorie",
                                      "hide-details": "auto",
                                      items: _setup.filterInsulationCategoriesG,
                                      "item-text": "description",
                                      "item-value": "name",
                                      "persistent-placeholder": "",
                                      dense: "",
                                      outlined: _setup.props.editMode,
                                      readonly: !_setup.props.editMode,
                                    },
                                    model: {
                                      value: _setup.selectedData.gCategory,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _setup.selectedData,
                                          "gCategory",
                                          $$v
                                        )
                                      },
                                      expression: "selectedData.gCategory",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c(_setup.ValidationProvider, {
                                    attrs: {
                                      name: "G-waarde",
                                      rules: "decimal:4|between:0,1.0",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c("v-text-field", {
                                                ref: "gValue",
                                                class: {
                                                  noBorders:
                                                    !_setup.gValueIsEdit,
                                                },
                                                attrs: {
                                                  label: "G-waarde",
                                                  "hide-details": "auto",
                                                  type: "number",
                                                  step: "0.01",
                                                  "persistent-placeholder": "",
                                                  dense: "",
                                                  "error-messages": errors,
                                                  outlined: _setup.gValueIsEdit,
                                                  readonly:
                                                    !_setup.gValueIsEdit,
                                                },
                                                model: {
                                                  value:
                                                    _setup.selectedData.gValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _setup.selectedData,
                                                      "gValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectedData.gValue",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4040830807
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }